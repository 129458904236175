<template>
  <div>
    <CRUDTopBar :title="config.title">
      <v-btn color="primary" rounded @click="pushCreateRoute"
        >New {{ config.name.toLowerCase() }}</v-btn
      >
    </CRUDTopBar>
    <v-container class="px-9">
      <v-row v-if="config.allowSearch" no-gutters>
        <v-col>
          <v-text-field
            v-model="search"
            label="Search"
            placeholder="Search"
            append-icon="mdi-magnify"
            @keydown.enter="loadAll"
            @blur="loadAll"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row v-if="config.paramOptions" no-gutters>
        <v-col>
          <v-switch
            v-for="key in Object.keys(config.paramOptions)"
            :key="key"
            v-model="paramOptions[key]"
            :label="config.paramOptions[key].label"
            class="ma-0"
            @change="loadAll"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            :headers="headers"
            :items="tableItems"
            :sort-by.sync="tableOptions.sortBy"
            :sort-desc.sync="tableOptions.sortDesc"
            class="elevation-1"
            :loading="isLoading"
            @click:row="handleRowClick"
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import api from '../../utils/api-axios';
import configs from './configs';
import CRUDTopBar from '../../components/common/TopBar';
import clone from 'clone-deep';

export default {
  components: {
    CRUDTopBar,
  },
  data() {
    return {
      isLoading: false,
      error: null,
      search: '',
      items: [],
      paramOptions: this.getParamOptions(),
      tableOptions: this.getTableOptions(),
    };
  },
  computed: {
    type() {
      return this.$route.params.type;
    },
    config() {
      return configs[this.type];
    },
    headers() {
      return Object.keys(this.config.table).map(key => ({
        text: this.config.table[key].label,
        value: key,
      }));
    },
    tableItems() {
      return this.items.map(originalItem => {
        let item = clone(originalItem);
        this.convertItemValues(item);
        this.resolveItemAttributes(item);
        return item;
      });
    },
    filterParams() {
      return Object.keys(this.paramOptions)
        .filter(key => this.paramShouldBeSent(key))
        .reduce((params, key) => {
          params[key] = this.paramOptions[key];
          return params;
        }, {});
    },
  },
  created() {
    this.loadAll();
  },
  methods: {
    loadAll() {
      this.isLoading = true;
      return api
        .get(this.config.apiUrl, {
          params: {
            search: this.search,
            ...this.filterParams,
          },
        })
        .then(res => res.data)
        .then(items => this.$set(this, 'items', items))
        .then(() => (this.isLoading = false));
    },
    handleRowClick(clickedItem) {
      this.pushUpdateRoute(clickedItem[this.config.identifierKey]);
    },
    pushUpdateRoute(id) {
      if ((this.config.editorPages || {}).update) {
        this.$router.push({
          ...this.config.editorPages.update,
          params: {
            ...(this.config.editorPages.update.params || {}),
            id,
          },
        });
      } else {
        this.$router.push({
          name: 'CRUDUpdateItem',
          params: {
            type: this.type,
            id: id,
          },
        });
      }
    },
    pushCreateRoute() {
      if ((this.config.editorPages || {}).create) {
        this.$router.push({
          ...this.config.editorPages.create,
          params: {
            ...(this.config.editorPages.create.params || {}),
          },
        });
      } else {
        this.$router.push({
          name: 'CRUDCreateItem',
          params: { type: this.type },
        });
      }
    },
    convertItemValues(item) {
      Object.keys(item).forEach(key => {
        if (this.config.table[key] && this.config.table[key].transformer) {
          item[key] = this.config.table[key].transformer(item[key]);
        }
      });
      return item;
    },
    getParamOptions() {
      const paramOptionsConfig = configs[this.$route.params.type].paramOptions;
      if (!paramOptionsConfig) return {};

      const paramOptions = {};
      Object.keys(paramOptionsConfig).forEach(
        key => (paramOptions[key] = paramOptionsConfig[key].default || false),
      );
      return paramOptions;
    },
    getTableOptions() {
      const tableOptionsConfig = configs[this.$route.params.type].tableOptions;
      if (!tableOptionsConfig) return {};
      return tableOptionsConfig;
    },
    resolveItemAttributes(item) {
      Object.keys(this.config.table).forEach(key => {
        if (this.config.table[key].resolver) {
          item[key] = this.config.table[key].resolver(item);
        }
      });
    },
    paramShouldBeSent(key) {
      return (
        this.filterOutNonSendFalses(key) && this.filterOutNonSendTrues(key)
      );
    },
    filterOutNonSendFalses(key) {
      return (
        this.paramOptions[key] ||
        this.config.paramOptions[key].sendFalse ||
        this.config.paramOptions[key].sendFalse === undefined
      );
    },
    filterOutNonSendTrues(key) {
      return (
        !this.paramOptions[key] ||
        this.config.paramOptions[key].sendTrue ||
        this.config.paramOptions[key].sendTrue === undefined
      );
    },
  },
};
</script>
